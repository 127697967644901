@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');


.formContainer {
  background: rgba(255, 255, 255, 0.95);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  margin: 2rem auto;
  position: relative;
}

.form {
  display: flex;
  flex-direction: column;
}

.moreMessages {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 15px 20px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  color: #ffffff;
  font-size: 1.1em;
  font-weight: 500;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.moreMessages:hover {
  background-color: rgba(255, 255, 255, 0.3);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.inputField {
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease-in-out;
}

.inputField:focus {
  border-color: #ff69b4;
  outline: none;
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
}

.submitButton, .closeButton {
  padding: 1rem 2rem;
  border: none;
  border-radius: 50px;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.submitButton {
  background: linear-gradient(135deg, #ff69b4, #8a2be2);
  color: #fff;
}

.submitButton:hover {
  background: linear-gradient(135deg, #8a2be2, #ff69b4);
}

.closeButton {
  background: #ccc;
  color: #333;
}

.closeButton:hover {
  background: #aaa;
}

@media (max-width: 768px) {
  .formContainer {
    padding: 1.5rem;
  }

  .inputField {
    font-size: 0.9rem;
  }

  .submitButton, .closeButton {
    padding: 0.8rem 1.5rem;
    font-size: 0.9rem;
  }
}

.about {
  padding: 8rem 0;
  background: linear-gradient(135deg, #ff69b4, #8a2be2);
  position: relative;
  overflow: hidden;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.about.visible {
  opacity: 1;
  transform: translateY(0);
  animation: fadeInUp 1s ease-out;
}

.about::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 70%);
  animation: rotate 30s linear infinite;
}

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
  z-index: 1;
}

.heading {
  font-family: 'Poppins', sans-serif;
  font-size: 3.5rem;
  text-align: center;
  margin-bottom: 4rem;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  position: relative;
  display: inline-block;
}

.heading::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, #ff69b4, #8a2be2);
  transform: scaleX(0);
  transition: transform 0.5s ease-in-out;
}

.heading:hover::after {
  transform: scaleX(1);
}

.content {
  display: flex;
  align-items: center;
  gap: 4rem;
  flex-wrap: wrap;
}

.imageContainer {
  flex: 1;
  position: relative;
  perspective: 1000px;
}

.image {
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
}

.imageContainer:hover .image {
  transform: scale(1.1);
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.25);
}

.imageCaption {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  color: #333;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.imageContainer:hover .imageCaption {
  transform: translateX(-50%) translateY(-10px);
}

.textContent {
  flex: 1;
}

.story {
  font-family: 'Poppins', sans-serif;
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 2rem;
  line-height: 1.8;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.2);
  position: relative;
  padding-left: 20px;
}

.story::before {
  content: '"';
  position: absolute;
  left: 0;
  top: -10px;
  font-size: 3rem;
  color: #ff69b4;
  opacity: 0.7;
}

.subheading {
  font-family: 'Poppins', sans-serif;
  font-size: 2rem;
  color: #fff;
  margin-bottom: 1.5rem;
  position: relative;
  display: inline-block;
}

.subheading::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, #ff69b4, #8a2be2);
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.subheading:hover::after {
  transform: scaleX(1);
}

.messageGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2.5rem;
}

.messageCard {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.messageCard::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 105, 180, 0.2), rgba(138, 43, 226, 0.2));
  transform: rotate(45deg);
  transition: transform 0.3s ease-in-out;
}

.messageCard:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.messageCard:hover::before {
  transform: rotate(0deg);
}

.wolof {
  display: block;
  font-weight: 700;
  color: #FF69B4;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  position: relative;
  z-index: 1;
}

.french {
  display: block;
  font-style: italic;
  color: #555;
  position: relative;
  z-index: 1;
}

.btn {
  display: inline-block;
  padding: 1rem 2rem;
  background: linear-gradient(135deg, #ff69b4, #8a2be2);
  color: #fff;
  text-decoration: none;
  border-radius: 50px;
  font-family: 'Poppins', sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.911);
  position: relative;
  overflow: hidden;
}

.btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, #ffffff, transparent);
  transition: all 0.4s ease-in-out;
}

.btn:hover::before {
  left: 100%;
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px #f8f5f5;
}

@media (max-width: 992px) {
  .content {
    flex-direction: column;
  }
  .imageContainer {
    margin-bottom: 3rem;
  }
  .heading {
    font-size: 3rem;
  }
  .subheading {
    font-size: 1.8rem;
  }
  .story {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 2.5rem;
  }
  .subheading {
    font-size: 1.6rem;
  }
  .story {
    font-size: 1rem;
  }
  .messageGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 2.2rem;
  }
  .subheading {
    font-size: 1.4rem;
  }
  .story {
    font-size: 0.9rem;
  }
  .messageGrid {
    grid-template-columns: 1fr;
  }
  .imageCaption {
    position: absolute;
    bottom: -10px; /* Adjust bottom spacing for mobile */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 255, 255, 0.9);
    padding: 0.3rem 0.5rem; /* Reduce padding for mobile */
    border-radius: 15px; /* Adjust border radius for mobile */
    font-family: 'Poppins', sans-serif;
    font-size: 0.8rem; /* Reduce font size for mobile */
    color: #333;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center; /* Center the text */
    width: calc(100% - 20px); /* Ensure it fits within the container */
    white-space: normal; /* Allow text wrapping */
  }
}

