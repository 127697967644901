@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.footer {
  padding: 6rem 0 4rem;
  background-color: #f9f9f9;
  color: #333;
  position: relative;
  overflow: hidden;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.footer.visible {
  opacity: 1;
  transform: translateY(0);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  text-align: center;
  position: relative;
  z-index: 2;
}

.socialLinks {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin-bottom: 3rem;
}

.socialItem {
  margin: 0 1.5rem;
  position: relative;
}

.socialIcon {
  font-size: 2.5rem;
  color: #8A2BE2;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.socialIcon.active {
  transform: scale(1.2) rotate(360deg);
  color: #FF69B4;
}

.socialName {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  font-family: 'Poppins', sans-serif;
  font-size: 0.8rem;
  white-space: nowrap;
  color: #333;
}

.socialItem:hover .socialName {
  opacity: 1;
  bottom: -30px;
}

.copyright {
  font-family: 'Poppins', sans-serif;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  position: relative;
  z-index: 2;
}

.heart {
  color: #FF69B4;
  display: inline-block;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.wavesContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  overflow: hidden;
}

.wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat-x;
  background-position: 0 bottom;
  transform-origin: center bottom;
}

.wave1 {
  background-color: #FF69B4;
  animation: wave1 7s linear infinite;
  z-index: 1;
  opacity: 0.7;
}

.wave2 {
  background-color: #fff;
  animation: wave2 9s linear infinite;
  z-index: 2;
  opacity: 0.5;
}

.wave3 {
  background-color: #8A2BE2;
  animation: wave3 5s linear infinite;
  z-index: 3;
  opacity: 0.3;
}

@keyframes wave1 {
  0% { transform: translateX(0) scaleY(1); }
  50% { transform: translateX(-25%) scaleY(0.55); }
  100% { transform: translateX(-50%) scaleY(1); }
}

@keyframes wave2 {
  0% { transform: translateX(0) scaleY(1); }
  50% { transform: translateX(-30%) scaleY(0.6); }
  100% { transform: translateX(-60%) scaleY(1); }
}

@keyframes wave3 {
  0% { transform: translateX(0) scaleY(1); }
  50% { transform: translateX(-20%) scaleY(0.7); }
  100% { transform: translateX(-40%) scaleY(1); }
}

@media (max-width: 768px) {
  .socialLinks {
    flex-wrap: wrap;
  }
  .socialItem {
    margin: 1rem;
  }
  .copyright {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 4rem 0 3rem;
  }
  .socialIcon {
    font-size: 2rem;
  }
  .copyright {
    font-size: 0.9rem;
  }
}
