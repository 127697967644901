.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1rem;
  box-sizing: border-box;
}

.formContainer {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-sizing: border-box;
}

.closeButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #999;
  transition: color 0.3s ease;
}

.closeButton:hover {
  color: #F39F86;
}

.title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center;
}

.formContent {
  display: flex;
  flex-direction: column;
}

.cartSummary {
  background: #f9f9f9;
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.cartSummary h4 {
  margin-top: 0;
  color: #333;
}

.cartItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.cartTotal {
  margin-top: 1rem;
  text-align: right;
  font-size: 1.1rem;
  color: #F39F86;
}

.orderForm {
  flex: 1;
}

.formGroup {
  margin-bottom: 1rem;
}

.inputField,
.textareaField,
.selectField {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.inputField:focus,
.textareaField:focus,
.selectField:focus {
  outline: none;
  border-color: #F39F86;
}

.textareaField {
  resize: vertical;
}

.selectField {
  appearance: none;
  background: url('data:image/svg+xml;base64,...') no-repeat right 10px center; /* icône de flèche pour le select */
  background-size: 12px;
}

.submitButton {
  width: 100%;
  padding: 1rem;
  background-color: #F39F86;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #F9D976;
}

.submitButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.errorMessage {
  color: #ff4444;
  margin-top: 1rem;
  text-align: center;
}

@media (min-width: 768px) {
  .formContent {
    flex-direction: row;
    gap: 2rem;
  }

  .cartSummary {
    flex: 1;
    margin-bottom: 0;
  }

  .orderForm {
    flex: 2;
  }
}

@media (max-width: 480px) {
  .formContainer {
    padding: 1.5rem;
  }

  .title {
    font-size: 1.3rem;
  }

  .inputField,
  .textareaField,
  .selectField,
  .submitButton {
    font-size: 0.9rem;
  }

  .cartItem {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .cartItem > * {
    width: 100%;
  }

  .cartTotal {
    text-align: left;
    font-size: 1rem;
  }

  .cartSummary h4 {
    font-size: 1.2rem;
  }
}
