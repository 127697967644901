/* Général */
.cartPageWrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(135deg, #FF69B4, #8A2BE2);
  padding: 0 1rem; /* Ajouter un padding pour éviter que le contenu ne touche les bords */
}

/* Entête */
.cartHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

.siteLogo {
  font-family: 'Playfair Display', serif;
  font-size: 2rem;
  color: #333;
  text-decoration: none;
  font-weight: 700;
}

.cartNav {
  display: flex;
  gap: 1.5rem;
}

.cartNav a {
  color: #333;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  transition: color 0.3s ease;
}

.cartNav a:hover, .cartNav a.active {
  color: #F39F86;
}

.cartCount {
  background-color: #F39F86;
  color: white;
  border-radius: 50%;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  margin-left: 0.3rem;
}

/* Main */
.cartMain {
  flex: 1;
  padding: 2rem 5%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.cartContainer {
  background: white;
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1000px;
}

/* Contenu du panier */
.cartContent {
  display: flex;
  flex-direction: column;
}

.cartItems {
  flex: 1;
  margin-bottom: 2rem;
}

.cartItem {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid #eee;
  flex-direction: column; /* Changer la direction en colonne sur les petits écrans */
  align-items: flex-start;
  margin-bottom: 1rem; /* Ajouter un espace entre les éléments */
}

.itemImage {
  width: 100%; /* Ajuster la largeur à 100% pour les petits écrans */
  max-width: 80px; /* Conserver une taille maximale pour éviter les images trop grandes */
  border-radius: 10px;
  margin-right: 1rem;
}

.itemInfo {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem; /* Espacement entre les éléments */
}

.itemName {
  font-size: 1.2rem;
  margin: 0 0 0.5rem 0;
}

.itemPrice {
  font-weight: bold;
  color: #F39F86;
}

.itemActions {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  width: 100%; /* Prendre toute la largeur disponible */
  justify-content: space-between; /* Espacer les boutons */
}

.quantityButton {
  background: none;
  border: 1px solid #ddd;
  padding: 0.3rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.quantityButton:hover {
  background-color: #f0f0f0;
}

.itemQuantity {
  margin: 0 0.5rem;
  font-weight: bold;
}

.removeButton {
  background: none;
  border: none;
  color: #999;
  cursor: pointer;
  transition: color 0.3s ease;
  margin-left: 1rem;
}

.removeButton:hover {
  color: #F39F86;
}

/* Résumé du panier */
.cartSummary {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 1.5rem;
  margin-top: 2rem;
}

.totalPrice {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  text-align: right;
}

.totalPrice span {
  font-weight: bold;
  color: #F39F86;
}

.orderButton,
.continueShoppingBtn {
  display: block;
  text-decoration: none;
  width: 100%;
  padding: 0.8rem 1.5rem;
  background-color: #F39F86;
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
}

.orderButton:hover,
.continueShoppingBtn:hover {
  background-color: #F9D976;
}

.orderFormOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.orderFormContainer {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  max-width: 500px;
  width: 90%;
}

.cartFooter {
  text-align: center;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.1);
  color: #333;
}

.emptyCart {
  text-align: center;
  padding: 2rem;
}

.continueShoppingBtn {
  display: inline-block;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.continueShoppingBtn:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Responsivité */
@media (min-width: 768px) {
  .cartContent {
    flex-direction: row;
    gap: 2rem;
  }

  .cartItems {
    flex: 2;
    margin-bottom: 0;
  }

  .cartSummary {
    flex: 1;
    align-self: flex-start;
    position: sticky;
    top: 2rem;
    margin-top: 0;
  }

  .cartItem {
    flex-direction: row;
  }

  .itemImage {
    width: 80px;
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .cartHeader {
    flex-direction: column;
    align-items: flex-start;
  }

  .cartMain {
    padding: 1rem;
  }

  .cartContainer {
    padding: 1rem;
  }

  .cartItem {
    flex-direction: column;
    align-items: flex-start;
  }

  .itemImage {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .itemInfo {
    align-items: flex-start;
  }

  .itemActions {
    width: 100%;
    justify-content: space-between;
  }
}

@media (max-width: 480px) {
  .cartHeader {
    padding: 1rem 1rem;
  }

  .cartNav {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .siteLogo {
    font-size: 1.5rem;
  }

  .cartTitle {
    font-size: 1.5rem;
  }

  .totalPrice {
    font-size: 1rem;
  }
}
