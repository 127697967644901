@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

.home {
  background: linear-gradient(135deg, #FF69B4, #8A2BE2);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  position: relative;
  overflow: hidden;
  padding: 1rem; /* Padding général */
  box-sizing: border-box; /* Assurez-vous que le padding est inclus dans la largeur totale */
}

.home::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://source.unsplash.com/random/1920x1080');
  background-size: cover;
  background-position: center;
  opacity: 0.1;
  z-index: -1;
  animation: zoomBackground 20s infinite alternate;
}

@keyframes zoomBackground {
  0% { transform: scale(1); }
  100% { transform: scale(1.1); }
}

.container {
  max-width: 800px;
  padding: 2rem; /* Ajustement du padding */
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 1rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  box-sizing: border-box; /* Assurez-vous que le padding est inclus dans la largeur totale */
}

.container.visible {
  opacity: 1;
  transform: translateY(0);
}

.heading {
  font-family: 'Poppins', sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem; /* Ajustement de la marge */
  line-height: 1.4;
  color: #8A2BE2;
  text-align: center; /* Centrage du texte */
}

.highlight {
  color: #FF69B4;
  position: relative;
  display: inline-block;
}

.highlight::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background-color: rgba(255, 105, 180, 0.3);
  z-index: -1;
  transition: height 0.3s ease-in-out;
}

.highlight:hover::after {
  height: 100%;
}

.subheading {
  font-family: 'Poppins', sans-serif;
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 1.5rem; /* Ajustement de la marge */
  text-align: center; /* Centrage du texte */
}

.btnContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.5rem; /* Ajustement de la marge */
  flex-direction: column; /* Aligner les boutons verticalement */
}

.btn {
  display: inline-block;
  padding: 1rem 2rem;
  border-radius: 2rem;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  text-align: center; /* Centrage du texte */
  width: 100%; /* Assurez-vous que les boutons prennent toute la largeur */
  box-sizing: border-box; /* Assurez-vous que le padding est inclus dans la largeur totale */
}

.btn::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.6s ease-out, height 0.6s ease-out;
}

.btn:hover::before {
  width: 300px;
  height: 300px;
}

.btnPrimary {
  background-color: #8A2BE2;
  color: #fff;
}

.btnSecondary {
  background-color: #FF69B4;
  color: #fff;
}

.scrollIndicator {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.7;
  transition: opacity 0.3s ease-in-out;
}

.scrollIndicator:hover {
  opacity: 1;
}

.mouse {
  width: 30px;
  height: 50px;
  border: 2px solid #fff;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.mouseWheel {
  width: 4px;
  height: 8px;
  background-color: #fff;
  border-radius: 2px;
  animation: scrollWheel 1.5s infinite;
}

@keyframes scrollWheel {
  0% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(15px); opacity: 0; }
}

@media (max-width: 768px) {
  .heading {
    font-size: 2rem;
  }
  .container {
    max-width: 90%;
    padding: 2rem;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 1.5rem;
  }
  .heading {
    font-size: 1.5rem;
  }
  .subheading {
    font-size: 1rem;
  }
  .btn {
    padding: 0.8rem 1.5rem;
    font-size: 0.9rem;
  }
}
